var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "reconciliation-act-modal",
      "centered": "",
      "size": "xl",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        var _vm$supplier, _vm$supplier2;

        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "header-title-block d-flex justify-content-between w-100"
        }, [_c('div', {
          staticClass: "header-title-block__container"
        }, [_c('h5', {
          staticClass: "header-title-block__title"
        }, [_vm._v("Акт сверки")]), _c('div', {
          staticClass: "header-descriptoin"
        }, [_c('div', {
          staticClass: "header-descriptoin__delimiter"
        }), _c('div', {
          staticClass: "header-descriptoin__text"
        }, [_vm._v(_vm._s(((_vm$supplier = _vm.supplier) === null || _vm$supplier === void 0 ? void 0 : _vm$supplier.name) || ((_vm$supplier2 = _vm.supplier) === null || _vm$supplier2 === void 0 ? void 0 : _vm$supplier2.full_name)))])])]), _c('b-button', {
          staticClass: "close"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          },
          on: {
            "click": _vm.hideModal
          }
        })])], 1)])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "modal-body"
  }, [_c('head-reconciliation-act', {
    staticClass: "modal-body__head",
    attrs: {
      "entity_list": _vm.entityList
    },
    on: {
      "date_range": _vm.updateDateRange,
      "change_entity": _vm.changeEntity
    }
  }), _c('reconciliation-act-table', {
    attrs: {
      "items": _vm.reconciliationActList,
      "head_sum_total": _vm.headSumTotal,
      "pay_sum_total": _vm.paySumTotal,
      "begin_saldo": _vm.beginSaldo,
      "is_loading": _vm.isLoading
    }
  })], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }