<template>
  <div class="b-table-header">
    <div class="b-table-header__filter-block">
      <div class="b-table-header__filter">
        <date-picker
          v-model="filter_date_range"
          :clearable="false"
          type="daterange"
          :open="datepicker_open"
          :editable="false"
          format="dd.MM.yyyy"
          class="elm-calendar hidden-picker"
          placeholder="Рабочий период"
          @on-change="setPeriod({ period: 'custom' })"
          @on-clickoutside="clickedOutside"
        />
        <b-dropdown
          no-caret
          variant="none"
          :disabled="datepicker_open"
        >
          <template #button-content>
            <div
              style="
                background: white;
                padding: 8px 10px;
                display: flex;
                flex-direction: row;
                height: 32px;
                border: 1px solid #bcbcbc;
              "
            >
              <span style="color: black">{{ getPeriod }}</span>
              <img
                src="/img/icons/calendar-dark.svg"
                alt="calendar"
                class="ml-2"
              />
            </div>
          </template>
          <b-dropdown-item
            class="sm-dropdown"
            @click.stop="datepicker_open = true"
          >
            Указать период
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'today' })"
          >
            Сегодня
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'yesterday' })"
          >
            Вчера
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'week' })"
          >
            Текущая неделя
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'lweek' })"
          >
            Прошлая неделя
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'month' })"
          >
            Текущий месяц
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'lmonth' })"
          >
            Прошлый месяц
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: 'lyear' })"
          >
            Последние 365 дней
          </b-dropdown-item>
          <b-dropdown-item
            class="sm-dropdown"
            @click="setPeriod({ period: null })"
          >
            Все время
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="b-table-header__select-block">
        <e-select
          v-model="entityItem"
          class="e-select-el"
          selected_type="text"
          position="bottom"
          placeholder="Организация"
          placeholder-drop="Поиск"
          :options="entity_list"
          :serchable="false"
          @input="changeEntity"
        >
          <template #custom_name="{ item }">
            <div class="select">
              <div class="select__options-name">{{ item.name }}</div>
              <div class="select__options-description">
                <div class="select__options-description-item">ИНН: {{ item.inn }}</div>
                <div class="select__options-description-item">КПП: {{ item.kpp }}</div>
              </div>
              <div></div>
            </div>
          </template>
        </e-select>
      </div>
    </div>

    <div class="b-table-header__left-block">
      <b-button class="b-table-header__left-block-btn">Печать</b-button>
      <b-button class="b-table-header__left-block-btn">Скачать .xlsx</b-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'HeadReconciliationAct',

    props: {
      entity_list: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        entityItem: [],
        datepicker_open: false,
        filter_date_range: [],
        selected_type: null
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch'
      }),

      getPeriod() {
        switch (this.selected_type) {
          case 'today': {
            return 'Сегодня'
          }
          case 'yesterday': {
            return 'Вчера'
          }
          case 'week': {
            return 'Текущая неделя'
          }
          case 'lweek': {
            return 'Прошлая неделя'
          }
          case 'month': {
            return 'Текущий месяц'
          }
          case 'lmonth': {
            return 'Прошлый месяц'
          }
          case 'lyear': {
            return 'Последние 365 дней'
          }
          case 'custom': {
            const start = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[0] ? new Date(this.filter_date_range[0]) : new Date()
            )
            const end = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[1] ? new Date(this.filter_date_range[1]) : new Date()
            )
            return start + ' - ' + end
          }
          default: {
            return 'За все время'
          }
        }
      }
    },

    mounted() {
      this.entityItem.push(this.getCurrentBranch?.entities[0])
    },

    methods: {
      clickedOutside() {
        this.datepicker_open = false
      },

      changeEntity() {
        this.$emit('change_entity', this.entityItem[0].id)
      },

      setPeriod({ period }) {
        switch (period) {
          case 'today': {
            this.filter_date_range[0] = new Date(new Date().setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date().setHours(23, 59, 59))
            break
          }
          case 'yesterday': {
            const today = new Date()
            this.filter_date_range[0] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(23, 59, 59))
            break
          }
          case 'week': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() + 1
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'lweek': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() - 6
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'month': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth(), 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth() + 1, 0, 23, 59, 59)
            break
          }
          case 'lmonth': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 1, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            break
          }
          case 'lyear': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear() - 1, curr.getMonth(), curr.getDay(), 0, 0, 0)
            this.filter_date_range[1] = new Date()
            break
          }
          case 'custom': {
            // this.filter_date_range[1]?.setHours(23)
            // this.filter_date_range[1]?.setMinutes(59)
            // this.filter_date_range[1]?.setSeconds(59)
            let dt = new Date(this.filter_date_range[1])
            dt.setHours(23, 59, 59)
            this.filter_date_range[1] = dt
            this.datepicker_open = false
            this.moddate = new Date()
            // const today = new Date()
            // if (isMount && today.getDate() !== new Date(this.getPeriod.moddate).getDate()) {
            //   this.setPeriod({ period: null })
            // }
            break
          }
          default: {
            this.filter_date_range = []
            break
          }
        }
        this.selected_type = period
        this.updateDateRange()
      },

      updateDateRange() {
        // this.setDateRange({
        //   type: this.selected_type,
        //   date: this.filter_date_range,
        //   moddate: this.moddate
        // })
        this.$emit('date_range', this.filter_date_range)
      }
    }
  }
</script>

<style scoped lang="scss">
  .select {
    &__options-name {
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    &__options-description {
      display: flex;
    }

    &__options-description-item {
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-right: 12px;
    }
  }

  ::v-deep() {
    #dropdown_select {
      width: 300px !important;
    }
    .inc-filters {
      flex-wrap: wrap;
      grid-gap: 0.5rem;
    }

    .inc-select-picker {
      width: 230px;

      .ivu-tag {
        max-width: 110px;
      }
    }

    .datepicker-range {
      width: 210px;
    }

    .sm-dropdown > a {
      padding: 4px 12px !important;
    }

    .dropdown-menu {
      z-index: 2000 !important;
    }

    .hidden-picker > .ivu-date-picker-rel {
      max-width: 0px !important;
    }

    .hidden-picker > div > div > span {
      display: none;
    }
  }

  .b-table-header {
    display: flex;

    &__filter-block {
      display: flex;
      width: 100%;
    }

    &__select-block {
      width: 216px;
      margin-left: 16px;
    }

    &__left-block {
      display: flex;
    }

    &__left-block-btn {
      padding-left: 12px;
      padding-right: 12px;
      width: fit-content;
      white-space: nowrap;
      margin-left: 16px;
      border-radius: 2px;
      background: var(--gray-gray-50, #efefef);
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .not-collapsed img {
    transform: rotate(0deg) !important;
  }

  .collapsed img {
    transform: rotate(0deg) !important;
  }

  .hidden-picker {
    max-width: 0px !important;
  }

  .hidden-picker > div > div > input {
    display: none;
  }
</style>
