<template>
  <b-modal
    id="reconciliation-act-modal"
    centered
    size="xl"
    hide-footer
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-title-block d-flex justify-content-between w-100">
          <div class="header-title-block__container">
            <h5 class="header-title-block__title">Акт сверки</h5>

            <div class="header-descriptoin">
              <div class="header-descriptoin__delimiter"></div>
              <div class="header-descriptoin__text">{{ supplier?.name || supplier?.full_name }}</div>
            </div>
          </div>

          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hideModal"
            />
          </b-button>
        </div>
      </div>
    </template>

    <template>
      <div class="modal-body">
        <head-reconciliation-act
          class="modal-body__head"
          :entity_list="entityList"
          @date_range="updateDateRange"
          @change_entity="changeEntity"
        />
        <reconciliation-act-table
          :items="reconciliationActList"
          :head_sum_total="headSumTotal"
          :pay_sum_total="paySumTotal"
          :begin_saldo="beginSaldo"
          :is_loading="isLoading"
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  import HeadReconciliationAct from '../components/HeadReconciliationAct.vue'
  import ReconciliationActTable from '../components/ReconciliationActTable.vue'

  export default {
    name: 'ReconciliationActModal',

    components: {
      HeadReconciliationAct,
      ReconciliationActTable
    },

    props: {
      supplier: {
        type: Object,
        default: () => {}
      }
    },

    apollo: {
      ActSverki: {
        query: require('../gql/ActSverki.gql'),
        fetchPolicy: 'no-cache',
        skip() {
          return !this.supplier.id
        },
        variables() {
          return {
            entity: this.entityFilterId || this.getCurrentBranch?.entities[0]?.id,
            supplier: this.supplier.id,
            pagination: {
              skip: this.pagination.skip,
              take: this.pagination.take,
              period: {
                begin: this.date_range[0] ?? null,
                end: this.date_range[1] ?? null
              }
            }
          }
        },
        result({ data }) {
          this.reconciliationActList = data?.ActSverki?.list ?? []
          this.reconciliationActList.forEach((el) => {
            if (el.pay_sum) {
              el.rowTableColor = '#F8F8F9'
            }
          })

          this.total = data?.ActSverki?.total
          this.headSumTotal = data?.ActSverki?.head_sum_total
          this.paySumTotal = data?.ActSverki?.pay_sum_total
          this.beginSaldo = data?.ActSverki?.begin_saldo
          this.isLoading = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Branch: {
        query: require('../../operational-processes/gql/Branch.gql'),
        variables() {
          return {
            id: this.getCurrentBranch.id
          }
        },
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entityList = data.Branch?.entities ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        entityList: [],
        reconciliationActList: [],
        headSumTotal: null,
        paySumTotal: null,
        beginSaldo: null,
        isLoading: true,
        date_range: [],
        pagination: {
          skip: 0,
          take: 20
        },
        total: null,
        entityFilterId: ''
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('reconciliation-act-modal')
      },

      updateDateRange(date) {
        this.date_range = date
        this.$apollo.queries.ActSverki.refresh()
      },

      changeEntity(idEntity) {
        this.entityFilterId = idEntity
      }
    }
  }
</script>

<style scoped lang="scss">
  .header-container {
    width: 100%;
    .header-title-block__container {
      display: flex;
      align-items: center;
    }
    .header-descriptoin {
      margin-top: 2px;
      display: flex;
      align-items: center;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &__text {
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &__delimiter {
        border-radius: 14px;
        background: var(--text-placeholder-gray-300, #bcbcbc);
        width: 1px;
        height: 12px;
        margin-left: 11px;
        margin-right: 11px;
      }
    }
    .header-title-block {
      // margin-bottom: 16px;
      &__title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px !important;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 22px;

    &__head {
      margin-bottom: 16px;
    }
  }

  ::v-deep() {
    #resizeable-table {
      .foot {
        background: #f8f8f9;
        .text-nowrap {
          padding: 8px !important;
        }
      }
      th {
        height: 54px !important;
      }
    }
  }
</style>
