var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table-header"
  }, [_c('div', {
    staticClass: "b-table-header__filter-block"
  }, [_c('div', {
    staticClass: "b-table-header__filter"
  }, [_c('date-picker', {
    staticClass: "elm-calendar hidden-picker",
    attrs: {
      "clearable": false,
      "type": "daterange",
      "open": _vm.datepicker_open,
      "editable": false,
      "format": "dd.MM.yyyy",
      "placeholder": "Рабочий период"
    },
    on: {
      "on-change": function ($event) {
        return _vm.setPeriod({
          period: 'custom'
        });
      },
      "on-clickoutside": _vm.clickedOutside
    },
    model: {
      value: _vm.filter_date_range,
      callback: function ($$v) {
        _vm.filter_date_range = $$v;
      },
      expression: "filter_date_range"
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none",
      "disabled": _vm.datepicker_open
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "background": "white",
            "padding": "8px 10px",
            "display": "flex",
            "flex-direction": "row",
            "height": "32px",
            "border": "1px solid #bcbcbc"
          }
        }, [_c('span', {
          staticStyle: {
            "color": "black"
          }
        }, [_vm._v(_vm._s(_vm.getPeriod))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/calendar-dark.svg",
            "alt": "calendar"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.datepicker_open = true;
      }
    }
  }, [_vm._v(" Указать период ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'today'
        });
      }
    }
  }, [_vm._v(" Сегодня ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'yesterday'
        });
      }
    }
  }, [_vm._v(" Вчера ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'week'
        });
      }
    }
  }, [_vm._v(" Текущая неделя ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lweek'
        });
      }
    }
  }, [_vm._v(" Прошлая неделя ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'month'
        });
      }
    }
  }, [_vm._v(" Текущий месяц ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lmonth'
        });
      }
    }
  }, [_vm._v(" Прошлый месяц ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lyear'
        });
      }
    }
  }, [_vm._v(" Последние 365 дней ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: null
        });
      }
    }
  }, [_vm._v(" Все время ")])], 1)], 1), _c('div', {
    staticClass: "b-table-header__select-block"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "selected_type": "text",
      "position": "bottom",
      "placeholder": "Организация",
      "placeholder-drop": "Поиск",
      "options": _vm.entity_list,
      "serchable": false
    },
    on: {
      "input": _vm.changeEntity
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "select"
        }, [_c('div', {
          staticClass: "select__options-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "select__options-description"
        }, [_c('div', {
          staticClass: "select__options-description-item"
        }, [_vm._v("ИНН: " + _vm._s(item.inn))]), _c('div', {
          staticClass: "select__options-description-item"
        }, [_vm._v("КПП: " + _vm._s(item.kpp))])]), _c('div')])];
      }
    }]),
    model: {
      value: _vm.entityItem,
      callback: function ($$v) {
        _vm.entityItem = $$v;
      },
      expression: "entityItem"
    }
  })], 1)]), _c('div', {
    staticClass: "b-table-header__left-block"
  }, [_c('b-button', {
    staticClass: "b-table-header__left-block-btn"
  }, [_vm._v("Печать")]), _c('b-button', {
    staticClass: "b-table-header__left-block-btn"
  }, [_vm._v("Скачать .xlsx")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }