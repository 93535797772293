var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('resizable-table', {
    ref: "table_reconciliation_act",
    staticClass: "table-reconciliation-act",
    attrs: {
      "table_name": "reconciliation-act-tab",
      "top_sticky_row": true,
      "foot": true,
      "foot_row": false,
      "items": _vm.items,
      "default_fields": _vm.fields,
      "empty_text": "Пусто",
      "resizable": true,
      "busy": _vm.is_loading
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('div', {
          staticClass: "head-lable"
        })];
      },
      proxy: true
    }, {
      key: "top-sticky-row_date",
      fn: function () {
        return [_vm._v("Задолженность на начало")];
      },
      proxy: true
    }, {
      key: "top-sticky-row_unit",
      fn: function () {
        return [_c('div', {
          staticClass: "begin-saldo-unit"
        }, [_vm._v("руб")])];
      },
      proxy: true
    }, {
      key: "top-sticky-row_duty",
      fn: function () {
        var _vm$begin_saldo;

        return [_c('div', {
          staticClass: "begin-saldo-duty"
        }, [_vm._v(_vm._s((_vm$begin_saldo = _vm.begin_saldo) !== null && _vm$begin_saldo !== void 0 ? _vm$begin_saldo : 0))])];
      },
      proxy: true
    }, {
      key: "body_date",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.pay_sum ? _c('div', [_c('div', {
          staticClass: "pay-sum-text"
        }, [_vm._v("Платёж")])]) : _c('div', [_vm._v(" " + _vm._s(_vm.convertDate(item.head.date)) + " ")])];
      }
    }, {
      key: "body_supplier",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.pay_sum ? _c('div', [_c('div')]) : _c('div', [!item.head.supplierDocument.id && !item.head.supplierDocument.date ? _c('div', [_vm._v("--")]) : _c('div', [_c('div', {
          staticClass: "supplier-number"
        }, [_vm._v(_vm._s(item.head.supplierDocument.id))]), _c('div', {
          class: {
            'supplier-date': item.head.supplierDocument.id
          }
        }, [_vm._v(" " + _vm._s(_vm.convertDate(item.head.supplierDocument.date)) + " ")])])])];
      }
    }, {
      key: "body_number",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.pay_sum ? _c('div', [_c('div')]) : _c('div', [_vm._v(" " + _vm._s(item.head.number) + " ")])];
      }
    }, {
      key: "body_unit",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.pay_sum ? _c('div', [_c('div', [_vm._v("руб")])]) : _c('div', [_vm._v("руб")])];
      }
    }, {
      key: "body_sum",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.pay_sum ? _c('div', [_c('div')]) : _c('div', [_vm._v(" " + _vm._s(item.head.sum) + " ")])];
      }
    }, {
      key: "body_date_pay",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.pay_sum ? _c('div', [_vm._v(" " + _vm._s(_vm.convertDate(item.date)) + " ")]) : _c('div', [_vm._v("--")])];
      }
    }, {
      key: "body_pay_sum",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.pay_sum ? _c('div', [_c('div', [_vm._v(_vm._s(item.pay_sum))])]) : _c('div', [_vm._v("--")])];
      }
    }, {
      key: "body_duty",
      fn: function (_ref8) {
        var _item$need_sum;

        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s((_item$need_sum = item.need_sum) !== null && _item$need_sum !== void 0 ? _item$need_sum : 0))])];
      }
    }, {
      key: "foot_date",
      fn: function () {
        return [_vm._v("Итог")];
      },
      proxy: true
    }, {
      key: "foot_unit",
      fn: function () {
        return [_c('div', {
          staticClass: "foot-unit"
        }, [_vm._v("руб")])];
      },
      proxy: true
    }, {
      key: "foot_sum",
      fn: function () {
        return [_vm._v(_vm._s(_vm.head_sum_total))];
      },
      proxy: true
    }, {
      key: "foot_pay_sum",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.pay_sum_total) + " ")];
      },
      proxy: true
    }, {
      key: "foot_duty",
      fn: function () {
        return [_c('div', {
          staticClass: "foot-duty"
        }, [_vm._v(" " + _vm._s(_vm.getDutyTotal()) + " ")])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }