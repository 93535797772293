<template>
  <resizable-table
    ref="table_reconciliation_act"
    class="table-reconciliation-act"
    table_name="reconciliation-act-tab"
    :top_sticky_row="true"
    :foot="true"
    :foot_row="false"
    :items="items"
    :default_fields="fields"
    empty_text="Пусто"
    :resizable="true"
    :busy="is_loading"
  >
    <template #head_id>
      <div class="head-lable"></div>
    </template>

    <template #top-sticky-row_date>Задолженность на начало</template>

    <template #top-sticky-row_unit>
      <div class="begin-saldo-unit">руб</div>
    </template>

    <template #top-sticky-row_duty>
      <div class="begin-saldo-duty">{{ begin_saldo ?? 0 }}</div>
    </template>

    <template #body_date="{ item }">
      <div v-if="item.pay_sum">
        <div class="pay-sum-text">Платёж</div>
      </div>
      <div v-else>
        {{ convertDate(item.head.date) }}
      </div>
    </template>

    <template #body_supplier="{ item }">
      <div v-if="item.pay_sum">
        <div></div>
      </div>
      <div v-else>
        <div v-if="!item.head.supplierDocument.id && !item.head.supplierDocument.date">--</div>
        <div v-else>
          <div class="supplier-number">{{ item.head.supplierDocument.id }}</div>
          <div :class="{ 'supplier-date': item.head.supplierDocument.id }">
            {{ convertDate(item.head.supplierDocument.date) }}
          </div>
        </div>
      </div>
    </template>

    <template #body_number="{ item }">
      <div v-if="item.pay_sum">
        <div></div>
      </div>
      <div v-else>
        {{ item.head.number }}
      </div>
    </template>

    <template #body_unit="{ item }">
      <div v-if="item.pay_sum">
        <div>руб</div>
      </div>
      <div v-else>руб</div>
    </template>

    <template #body_sum="{ item }">
      <div v-if="item.pay_sum">
        <div></div>
      </div>
      <div v-else>
        {{ item.head.sum }}
      </div>
    </template>

    <template #body_date_pay="{ item }">
      <div v-if="item.pay_sum">
        {{ convertDate(item.date) }}
      </div>
      <div v-else>--</div>
    </template>

    <template #body_pay_sum="{ item }">
      <div v-if="item.pay_sum">
        <div>{{ item.pay_sum }}</div>
      </div>
      <div v-else>--</div>
    </template>

    <template #body_duty="{ item }">
      <div>{{ item.need_sum ?? 0 }}</div>
    </template>

    <template #foot_date>Итог</template>
    <template #foot_unit>
      <div class="foot-unit">руб</div>
    </template>
    <template #foot_sum>{{ head_sum_total }}</template>
    <template #foot_pay_sum> {{ pay_sum_total }} </template>
    <template #foot_duty>
      <div class="foot-duty">
        {{ getDutyTotal() }}
      </div>
    </template>
  </resizable-table>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import formatDate from '@/utils/formatDate'
  export default {
    name: 'ReconciliationActTable',
    components: {
      ResizableTable,
      formatDate
    },

    props: {
      items: {
        type: Array,
        default: () => []
      },
      head_sum_total: {
        type: Number,
        default: null
      },
      pay_sum_total: {
        type: Number,
        default: null
      },
      begin_saldo: {
        type: Number,
        default: null
      },
      is_loading: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        fields: [
          //   {
          //     key: 'id',
          //     checked: true,
          //     label: '',
          //     isRowHeader: true,
          //     width: 50
          //   },
          {
            key: 'date',
            checked: true,
            label: 'Дата отгрузки',
            width: 100
          },
          {
            key: 'supplier',
            checked: true,
            label: '№ Внеш. осн.',
            width: 100
          },
          {
            key: 'number',
            checked: true,
            label: '№ Накладной/акта',
            width: 130
          },
          {
            key: 'unit',
            checked: true,
            label: 'Ден. ед.',
            width: 70
          },
          {
            key: 'sum',
            checked: true,
            label: 'Сумма, ₽',
            width: 100
          },
          {
            key: 'date_pay',
            checked: true,
            label: 'Дата платежа',
            width: 100
          },
          {
            key: 'pay_sum',
            checked: true,
            label: 'Платёж, ₽',
            width: 100
          },
          {
            key: 'duty',
            checked: true,
            label: 'Задолженность, ₽',
            width: 120
          }
        ]
      }
    },

    methods: {
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      getDutyTotal() {
        return (this.begin_saldo - (this.head_sum_total - this.pay_sum_total)) * -1
      }
    }
  }
</script>

<style scoped lang="scss">
  .foot-duty {
    color: red;
  }

  .foot-unit {
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .begin-saldo-duty {
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .begin-saldo-unit {
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .pay-sum-text {
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .supplier-number {
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .supplier-date {
    color: var(--text-secondary-gray-400, #888);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  ::v-deep() {
    tfoot {
      position: sticky;
      bottom: 0;

      .foot {
        padding: 0 !important;
      }

      td {
        border-right: none;
        padding: 0 !important;
      }
    }

    th {
      height: 54px !important;
    }
  }
</style>
